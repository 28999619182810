import { Alert, Button, Divider, Pagination, Popconfirm, Space, Table, Tabs, TabsProps } from "antd";
import TouristList from "./ViewTouristGroup";
import { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import Moment from "react-moment";
import { Link } from "react-router-dom";
import ViewTouristGroup from "./ViewTouristGroup";
import { LoadingOutlined } from "@ant-design/icons";

const { Column } = Table;

const TouristGroupList = ()=>{
    const [list, setList] = useState(null);
    const [count, setCount] = useState(-1);
    const [currentGroup, setCurrentGroup] = useState(0);
    const [activeTab, setActiveTab] = useState('0');
    const [currentPage, setCurrentPage] = useState(1);

    const pageSize = 5;

    useEffect(()=>{
       fetchList();
    },      
      [currentPage]);

    const fetchList = ()=>{
      FetchWithAuth('/api/ns/tourist_group/list?offset='+(currentPage-1)*pageSize+'&limit='+pageSize).then((data)=>{
        const listData = data.data.list;
        setList(listData);
        setCount(data.data.count);
     });
    }


    const deleteGroup = async (id)=>{
      const result = await FetchWithAuth('/api/ns/tourist_group/delete?id='+id, {method: 'POST' } )
      if( result.data === 1){
        fetchList();
      }else{

      }

    }      

      const tab1 = <div>
        <Space>
            <Link to="/group/add"><Button>创建旅行团</Button></Link>
        </Space>
        <Table loading={list===null}
            dataSource={list} 
            pagination={{pageSize: pageSize, current: currentPage, total: count}}
            onChange={(p)=>{
              setCurrentPage(p.current)
            }}

            >
            <Column title="ID" dataIndex="id" key="age" />
            <Column title="旅行码" render={row=><Link to={"/group/view/"+row['id']}>{row['name']}</Link>} key="name" />
            <Column title="到达时间" render={row=><Moment format="YYYY-MM-DD">{row['arrive_date']}</Moment>} key="guide" />
            <Column title="离开时间" render={row=><Moment format="YYYY-MM-DD">{row['leave_date']}</Moment>} key="guide" />            
            <Column title="导游" render={row=><Link to={"/guide/view/"+row['guide_id']}>{row['guide_name']}</Link>} key="guide_name" />
            <Column title="导游二" render={row=><Link to={"/guide/view/"+row['guide2_id']}>{row['guide2_name']}</Link>} key="guide2_name" />
            <Column title="旅行社" render={row=><Link to={"/agency/view/"+row['agency_id']}>{row['agency_name']}</Link>} key="agency" />                        
            <Column title="操作" render={row=><div>
                <Space>
                    <Link to={"/group/transactions/"+row['id']}> <Button type="link">购物记录</Button> </Link>                    
                    <Link to={"/group/add_member/"+row['id']}> <Button>添加成员</Button> </Link>                              
                    <Link to={"/group/edit/"+row['id']}> <Button>编辑</Button> </Link>          
                    <Popconfirm
                    title={'确认'}
                    description={"确认要删除 "+row['name']+'?'}
                    onConfirm={()=>deleteGroup(row['id'])}
                    okText="确定"
                    cancelText="否"
                  ><Button>删除</Button></Popconfirm>
                </Space>
            </div>} />
        </Table>
      </div>


     const tab2 = <div>     
        {currentGroup>0&&<ViewTouristGroup id={currentGroup} />}
        {currentGroup===0&&<Alert message="没选中旅行团" />}
      </div>

      const items: TabsProps['items'] = [
        {
          key: '0',
          label: `旅行团列表`,
          children: tab1,
        },
        {
          key: '1',
          label: `详细信息`,
          children: tab2,
        },
      ];

    return <div>
        <Tabs activeKey={activeTab} items={items} onChange={(key)=>{setActiveTab(key)}} />        
    </div>
}

export default TouristGroupList;