import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Result,
  Select,
} from "antd";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Browse } from "digimaker-ui";
import { DeleteOutlined } from "@ant-design/icons";

export const AddGroup = () => {
  const [success, setSuccess] = useState(false);
  const [agencyList, setAgencyList] = useState([]);
  const [form] = Form.useForm();
  const [guideName, setGuideName] = useState("");
  const [guide2Name, setGuide2Name] = useState("");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    FetchWithAuth("/api/ns/agency/list?limit=-1").then((data) => {
      setAgencyList(data.data.list);
    });
  };

  const submit = async (values) => {
    const validValues = {};
    for (const key of Object.keys(values)) {
      const v = values[key];
      if (v != undefined) {
        if (key === "arrive_time" || key === "leave_time") {
          validValues[key] = v.format();
          continue;
        }

        validValues[key] = v;
      }
    }
    const result = await FetchWithAuth("/api/ns/tourist_group/add", {
      method: "POST",
      body: JSON.stringify(validValues),
    });

    if (result.data === 1) {
      setSuccess(true);
    } else {
    }
  };

  return (
    <div>
      <h2>添加旅行团</h2>
      {success && (
        <Result
          status="success"
          title="添加成功"
          subTitle="旅行团已经添加成功"
          extra={[
            <Link to="/group/list">
              <Button type="primary" key="back">
                回到列表
              </Button>
            </Link>,
            <Button key="add" onClick={() => setSuccess(false)}>
              再次添加
            </Button>,
          ]}
        />
      )}
      {!success && (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{}}
          form={form}
          onFinish={submit}
          autoComplete="off"
        >
          <Form.Item
            label="旅行码"
            name="name"
            rules={[{ required: true, message: "请输入名称" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="到达时间"
            name="arrive_time"
            rules={[{ required: true, message: "请输入名称" }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="离开时间"
            name="leave_time"
            rules={[{ required: true, message: "请输入名称" }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="旅行社"
            name="agency"
            rules={[{ required: true, message: "请选择旅行社" }]}
          >
            <Select showSearch placeholder="请选择">
              {agencyList.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="导游"
            name="guide"
            rules={[{ required: true, message: "请选择导游" }]}
          >
            <Input type="hidden" />
            <Browse
              contenttype={["user"]}
              buttonText="选择"
              parent={507}
              onConfirm={(content) => {
                form.setFieldValue("guide", content.id);
                setGuideName(content.metadata.name);
              }}
            />
            {guideName}
          </Form.Item>

          <Form.Item
            label="导游二"
            name="guide2"
            rules={[{ required: false, message: "请输入名称" }]}
          >
            <Input type="hidden" />
            <Browse
              contenttype={["user"]}
              buttonText="选择"
              parent={507}
              onConfirm={(content) => {
                form.setFieldValue("guide2", content.id);
                setGuide2Name(content.metadata.name);
              }}
            />
            {guide2Name}
            {guide2Name && (
              <Button
                type="link"
                onClick={() => {
                  form.setFieldValue("guide2", undefined);
                  setGuide2Name("");
                }}
              >
                <DeleteOutlined />
              </Button>
            )}
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              添加
            </Button>
            <Button type="link" onClick={() => window.history.back()}>
              返回
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
