import { Button, Checkbox, DatePicker, Form, Input, Result } from "antd"
import {FetchWithAuth} from 'digimaker-ui/util';
import { useState } from "react";
import { Link } from "react-router-dom";

export const AddAgency = ()=>{
    const [success, setSuccess] = useState(false);

    const submit = async (values)=>{       
        const result = await FetchWithAuth('/api/ns/agency/add', {
            method: 'POST',
            body: JSON.stringify(values),
        } )
        
        if(result.data===1){
          setSuccess(true);
        }else{

        }
        ;
    }

   

    return <div>

<h2>添加旅行社</h2>
{success&&<Result
    status="success"
    title="添加成功"
    subTitle="旅行社已经添加成功"
    extra={[
      <Link to='/agency/list'><Button type="primary" key="back">
        回到列表
      </Button></Link>,
      <Button key="add" onClick={()=>setSuccess(false)}>再次添加</Button>,
    ]}
  />}
{!success&&<Form
    name="basic"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    style={{ maxWidth: 600 }}
    initialValues={{}}
    onFinish={submit}
    autoComplete="off"
  >
    <Form.Item
      label="名称"
      name="name"
      rules={[{ required: true, message: '请输入名称' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Button type="primary" htmlType="submit">
        添加
      </Button>
    </Form.Item>
  </Form>}

    </div>
}