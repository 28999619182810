import { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import { Button, Popconfirm, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

export const GuideList = ()=>{
    const [list, setList] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const pageSize = 5;

    useEffect(()=>{
       fetchList();
    },      
      [currentPage]);

    const fetchList = ()=>{
      FetchWithAuth('/api/content/list/user?parent='+process.env.REACT_APP_GUIDE_PARENT_ID+'&offset='+((currentPage-1)*pageSize)+'&limit='+pageSize).then((data)=>{
        const listData = data.data.list;
        setList(listData);
        setCount(data.data.count);
     });
    }

    return <div>        
        <h2>导游列表</h2>
        <Link to={"/create/"+process.env.REACT_APP_GUIDE_PARENT_ID+"/user"}><Button>添加</Button></Link>

      <Table loading={list===null} dataSource={list}
        pagination={{pageSize: pageSize, current: currentPage, total: count}}
        onChange={(p)=>{
          setCurrentPage(p.current)
        }}
        >
            <Column title="ID" dataIndex="id" key="age" />
            <Column title="姓名" render={row=><Link to={"/guide/view/"+row.id}>{row.metadata['name']}</Link>} key="name" />                        
            <Column title="操作" render={row=><div>
                <Space>
                <Link to={"/guide/commision/"+row.id}> <Button type="link">佣金</Button> </Link>
                    <Link to={"/edit/"+row.location.id}> <Button>编辑</Button> </Link>
                    <Popconfirm
                    title={'确认'}
                    description={"确认要删除 "+row['name']+'?'}
                    okText="确定"
                    cancelText="否"
                  ><Button>删除</Button></Popconfirm>
                </Space>
            </div>} />
        </Table>
    </div>
}