import React, { useState } from "react";
import logo from "./logo.svg";
import {
  Breadcrumb,
  Button,
  Divider,
  Layout,
  Menu,
  MenuProps,
  Select,
  Space,
} from "antd";
import "./App.css";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {
  AppstoreOutlined,
  DashboardOutlined,
  GroupOutlined,
  LaptopOutlined,
  MailOutlined,
  NotificationOutlined,
  QuestionOutlined,
  SettingOutlined,
  ShoppingOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import TouristGroupList from "./components/tourist_group/TouristGroupList";
import util from "digimaker-ui/util";
import {
  BrowserRouter,
  BrowserRouter as Router,
  createBrowserRouter,
  createRoutesFromElements,
  Link,
  NavLink,
  Outlet,
  redirect,
  Route,
  RouterProvider,
  Routes,
  Navigate,
} from "react-router-dom";
import { AddGroup } from "./components/tourist_group/AddGroup";
import { GuideList } from "./components/guide/GuideList";
import { AgencyList } from "./components/agency/AgencyList";
import { ViewGuide } from "./components/guide/ViewGuide";
import { DMInit } from "digimaker-ui";
import { Edit, Create } from "digimaker-ui/actions";
import { DMRouteRender } from "./DMRouteRender";
import { AddAgency } from "./components/agency/AddAgency";
import { ViewAgency } from "./components/agency/ViewAgency";
import { AddTourist } from "./components/tourist/AddTourist";
import TouristList from "./components/tourist_group/ViewTouristGroup";
import ViewTouristGroup from "./components/tourist_group/ViewTouristGroup";
import { ListProduct } from "./components/product/ListProduct";
import { Login } from "./components/user/Login";
import { Logout } from "./components/user/Logout";
import TransferList from "antd/es/transfer/list";
import { TransactionList } from "./components/transaction/TransactionList";
import { UserProfile } from "./components/user/UserProfile";
import { DashBoard } from "./components/dashboard/DashBoard";
import { TransactionImport } from "./components/transaction/TransactionImport";
import ErrorBoundary from "./ErrorBoundary";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  getItem(<NavLink to="/">面板</NavLink>, "dashboard", <DashboardOutlined />),
  getItem("购物信息", "sub1", <ShoppingOutlined />, [
    getItem(<NavLink to="/transaction/list">购物记录</NavLink>, "1"),
    getItem(<Link to="/order/list">订单列表</Link>, "6"),
    getItem(<Link to="/product/list">产品管理</Link>, "2"),
  ]),

  getItem("旅行信息", "sub2", <TeamOutlined />, [
    getItem(<Link to="/group/list">旅行团</Link>, "group_list"),
    getItem(<Link to="/guide/list">导游</Link>, "guide"),
    getItem(<Link to="/agency/list">旅行社</Link>, "g2"),
  ]),

  { type: "divider" },

  getItem("系统设置", "sub4", <SettingOutlined />, [
    getItem("用户管理", "9"),
    getItem("店面管理", "10"),
  ]),

  // getItem('设置一', '13'),
  // getItem('设置二', '14'),
];

// util.setRefreshToken(
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTE5NzMzMjgsImd1aWQiOiJjZjgwNzdjMi1iNmQyLTQyZWYtYjc5YS0wMTRjNTY5ZWZiZjciLCJ1c2VyX2lkIjoxfQ.7s5gD9fFcusOZ9sfn0361O2p01x2hfaQngozS6Dbfzs"
// );

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route
              path="*"
              element={
                <DMInit
                  viewSettings={(contenttype: string) => {
                    // if (contenttype === "user") {
                    return {
                      inline_fields: ["name"],
                      browselist: {
                        viewmode: "list",
                        columns: [
                          { header: "id", field: "id" },
                          { header: "名称", render: (c) => c.metadata.name },
                        ],
                        sort_default: [["priority", "desc"]],
                      },
                    };
                    // }
                    // return { inline_fields: [], browselist: {} };
                  }}
                >
                  <Layout style={{ minHeight: "100vh" }}>
                    <Sider width={200} style={{ background: "#f0f0f0" }}>
                      <div className="logo">
                        <Link to="/">
                          <img src="/logo.png" />
                        </Link>
                      </div>
                      <Menu
                        mode="inline"
                        defaultSelectedKeys={["dashboard"]}
                        defaultOpenKeys={["sub1", "sub2"]}
                        style={{ height: "100%", borderRight: 0 }}
                        items={items}
                      />
                    </Sider>
                    <Layout style={{ padding: "0 24px 24px" }}>
                      <Breadcrumb style={{ margin: "16px 0" }}>
                        <Breadcrumb.Item>
                          <Link to="/">主页</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <Link to="/group/list">旅行信息</Link>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                      <div
                        style={{
                          position: "absolute",
                          right: 0,
                          padding: 5,
                          display: "flex",
                        }}
                      >
                        <Space>
                          选择门店:
                          <Select placeholder="选择" style={{ width: 150 }}>
                            <Select.Option value={0}>所有门店</Select.Option>
                            <Select.Option value={1}>
                              Apotek Opera
                            </Select.Option>
                            <Select.Option value={2}>
                              Apotek Rådhuset
                            </Select.Option>
                          </Select>
                          <Divider type="vertical" />
                          <UserProfile />
                          <a href="/doc.html">帮助</a>
                          <Logout />
                        </Space>
                      </div>
                      <Content
                        style={{
                          padding: 24,
                          margin: 0,
                          minHeight: 280,
                          background: "#fcfcfc",
                        }}
                      >
                        <Routes>
                          <Route
                            path="create/:id/:type"
                            element={
                              <DMRouteRender
                                render={(params, navigate, location) => (
                                  <Create
                                    parent={parseInt(params.id)}
                                    contenttype={params.type}
                                    afterAction={() => {}}
                                  />
                                )}
                              />
                            }
                          />
                          <Route
                            path="edit/:id"
                            element={
                              <DMRouteRender
                                render={(params, navigate, location) => (
                                  <Edit
                                    id={parseInt(params.id)}
                                    afterAction={() => {}}
                                  />
                                )}
                              />
                            }
                          />
                          <Route
                            path="group/list"
                            element={<TouristGroupList />}
                          ></Route>
                          <Route
                            path="group/view/:group"
                            element={<ViewTouristGroup />}
                          ></Route>
                          <Route
                            path="group/add"
                            element={<AddGroup />}
                          ></Route>
                          <Route
                            path="group/add_member/:group"
                            element={<AddTourist />}
                          ></Route>

                          <Route
                            path="guide/list"
                            element={<GuideList />}
                          ></Route>
                          <Route
                            path="guide/view/:id"
                            element={<ViewGuide />}
                          ></Route>

                          <Route
                            path="agency/list"
                            element={<AgencyList />}
                          ></Route>
                          <Route
                            path="agency/add"
                            element={<AddAgency />}
                          ></Route>
                          <Route
                            path="agency/view/:id"
                            element={<ViewAgency />}
                          ></Route>

                          <Route
                            path="transaction/list"
                            element={<TransactionList />}
                          ></Route>
                          <Route
                            path="transaction/import"
                            element={<TransactionImport />}
                          ></Route>

                          <Route
                            path="product/list"
                            element={<ListProduct />}
                          ></Route>

                          <Route path="/" element={<DashBoard />}></Route>
                        </Routes>
                      </Content>
                    </Layout>
                  </Layout>
                </DMInit>
              }
            />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

export default App;
