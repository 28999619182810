import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import util from 'digimaker-ui/util';
import { useNavigate } from "react-router";

export const Login = ()=>{
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const submit = async (values)=>{
        fetch(process.env.REACT_APP_REMOTE_URL + '/auth/auth', { method: 'post', body: JSON.stringify(values) })
        .then((response) => response.json())
        .then((res) => {
        if( res.error === false ){
            util.setRefreshToken(res.data.refresh_token);
            navigate( '/' );
        }else{
            messageApi.error(res.data.message);
        }
        })
        .catch((err) => {
            messageApi.error(err.toString());
        });
    }

    return <div style={{maxWidth: 600, margin:'20vh auto auto auto'}}>
    <h2>挪森旅游管理系统</h2>
    <hr />
    <br />
    {contextHolder}
    <Form onFinish={submit} labelCol={{ span: 8 }}>
        <Form.Item required label="用户名" name="username">
            <Input />
        </Form.Item>
        <Form.Item required label="密码" name="password">
        <Input type="password" />
    </Form.Item>

    <div>
        <Button type="primary" htmlType="submit">登录</Button>
    </div>
    </Form>

    
    </div>;
}