
import { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import { Button, Card, Popconfirm, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import { Link, useParams } from "react-router-dom";

export const ViewAgency = ()=>{
    const params = useParams();
    const [data, setData] = useState({} as any);

    useEffect(()=>{
       fetchList();
    },      
      []);

    const fetchList = ()=>{
      FetchWithAuth('/api/ns/agency/get?id='+params.id).then((resData)=>{
        setData(resData.data);
     });
    }

    return <div>        
        <h2>旅行社</h2>
        <Card size="small" title='旅行社信息'>
          <div><label>名称：</label><span>{data.name}</span></div>            
        </Card>

    </div>
}