import { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import { Alert, Button, Checkbox, Col, Input, Radio, Result, Row, Select, Space, Steps, Table, Tabs, Typography } from "antd";
import Column from "antd/es/table/Column";
import { Link } from "react-router-dom";
import { UploadCsv } from "../util/UploadCsv";
import useMessage from "antd/es/message/useMessage";
import { ArrowLeftOutlined, ArrowRightOutlined, CheckCircleOutlined, CheckOutlined, DoubleRightOutlined, ImportOutlined, InfoCircleOutlined, SendOutlined, TransactionOutlined, UserOutlined, WarningFilled, WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import { util } from "../util/uti";

const { Title } = Typography;

export const TransactionImport = ()=>{
    const [receiptData, setReceiptData] = useState(null);
    const [taxData, setTaxData] = useState(null);
    const [taxTourists, setTaxTourists] = useState(null);
    const [taxTouristsValidated, setTaxTouristsValidated] = useState(null);    

    const [receiptsValid, setReceiptsValid] = useState([]);
    const [receiptsNoTourist, setReceiptsNoTourist] = useState([]);    
    const [receiptsInvalid, setReceiptsInvalid] = useState([]);

    const [step, setStep] = useState(0);
    
    const [message, messageHolder] = useMessage();

    // in step 0
    const [onlyShowUnmatched, setOnlyShowUnmatched] = useState(false);

    useEffect(()=>{
     },[]);
 
   
    const validateTourist = ()=>{
        FetchWithAuth('/api/ns/transaction/validate_passport',{method:'POST', body: JSON.stringify(taxTourists)}).then((data)=>{
            let result = data.data;        
            setTaxTouristsValidated(result);
         });
    }


    const filterTaxTourists = (taxData)=>{
        let taxList = [];
        taxData.map((item)=>{
            const firstname = item.Firstname;
            const lastname = item.Lastname;
            const passport = item.Passport;
            let invoiceNoStr = item['Custom Invoicenumber'];     
            let transactionDate = moment( item['Transaction Date'], 'DD.MM.YYYY' ).format('YYYY-MM-DD'); 
            if( invoiceNoStr ){
                for( let invoiceNo of invoiceNoStr.split(',') ){
                    if( invoiceNo.startsWith('=') ){                       
                        invoiceNo = invoiceNo.substring(2);
                        invoiceNo = invoiceNo.substring(0, invoiceNo.length-1);
                    }
                    taxList.push({
                        firstname:firstname,
                        lastname:lastname,
                        passport:passport,
                        invoice_no: invoiceNo,
                        transaction_date: transactionDate
                    });
                }
            }            
        });
        setTaxTourists(taxList);
    }

    const buildTransactionList = ()=>{
        let receiptList = [];    
        receiptData.map((item, i)=>{
            const invoiceNo = item['Ekspnr'];
            const productName = item['VareKundeTilleggtrekk'];
            const productNo = item['VarenrReseptnrFakturanrTilleggtrekknr'];
            const amount = util.stringToFloat(item['Belop']);
            const unit = item['Kvantum']+item['Enhet'];
            const quantity = Math.floor(util.stringToFloat(item['Antall']));
            const transactionTime = moment( item['Dato']+' '+item['Klokke'], 'DD.MM.YYYY hh:mm' ).format('YYYY-MM-DD hh:mm'); 
            
            const taxRecord = taxTouristsValidated.find(i=>i['invoice_no']===invoiceNo);
            
            let taxMatchResult = null;
            if( taxRecord && taxRecord.match_result && taxRecord.match_result.length === 1 ){
                taxMatchResult = taxRecord.match_result[0];
            }
            receiptList.push({
                index: i+1,
                product_name: productName,
                product_no: productNo,
                invoice_no: invoiceNo,
                amount: amount,
                transaction_time: transactionTime,
                firstname: taxRecord?.firstname,
                lastname: taxRecord?.lastname,
                passport_no: taxRecord?.passport ,
                quantity: quantity,     
                unit: unit,
                taxRecord: taxRecord,
                tax_match_result: taxMatchResult
            });
        })
        setReceiptsValid(receiptList.filter(i=>(i.taxRecord&&i.tax_match_result)));
        setReceiptsNoTourist(receiptList.filter(i=>i.taxRecord&&!i.tax_match_result));        
        setReceiptsInvalid(receiptList.filter(i=>!i.taxRecord));
    }

    const submit = ()=>{
        let sendData = [];
        for(const item of receiptsValid){
            let sendItem = {...item, amount:Math.floor(item.amount*100), tourist_id: item.tax_match_result.id };
            delete(sendItem['tax_match_result']);
            delete(sendItem['taxRecord']);            
            sendData.push(sendItem)
        }

        FetchWithAuth('/api/ns/transaction/import',{method:'POST', body: JSON.stringify(sendData)}).then((resp)=>{
            if( resp.data === 1 ){
                setStep(3);
            }
         });
    }

    return <div>
        <Title level={2}>导入购物记录</Title> {messageHolder}
        <br />       
        <Steps
            style={{width: '70%', margin: 'auto'}}
            size="small"
            current={step}
            items={[
            {
                title: '上传文件',
            },
            {
                title: '游客匹配列表',
            },
            {
                title: '购物记录匹配列表',
            },
            {
                title: '导入结果',
            }
            ]}
        />
    
        <div style={{padding: 10}}>

        <div style={{display: step===0?'block':'none'}}>
            <br />
            <Row style={{padding: 5}}>
            <Col style={{width:200}}>交易记录 (以-receipt.csv结尾):</Col>
            <Col><UploadCsv button='上传csv' validate={(file)=>{
                if( !file.name.endsWith('-receipt.csv') ){
                    message.error('请选用-receipt.csv结尾');
                    return false
                }
                return true;
            }} onFinish={(data) =>{
                setReceiptData(data);
            }} /></Col>
        </Row>
        <Row style={{padding: 5}}>
            <Col style={{width:200}}>退税记录 (以-tax.csv结尾):</Col>        
            <Col><UploadCsv button='上传csv' validate={(file)=>{
                if( !file.name.endsWith('-tax.csv') ){
                    message.error('请选用-tax.csv结尾');
                    return false
                }
                return true;
            }} onFinish={(data) =>{
                setTaxData(data);
                filterTaxTourists(data);
            }} /></Col>
        </Row>
            
        <Row style={{padding: 5}}>
            <Col style={{width:200}}>购物门店:</Col>    
            <Col>
                <Select style={{width: 200}} placeholder="选择门店">
                    <Select.Option value={1}>Apotek Opera</Select.Option>
                    <Select.Option value={2}>Vitalis Apotek</Select.Option>
                </Select>
            </Col>
        </Row>
        <br />
        <div>
                <Button onClick={()=>{validateTourist();setStep(1) }} type="primary" disabled={!(receiptData&&taxData)}><ArrowRightOutlined /> 验证 & 下一步</Button>
            </div>
        </div>

        {step===1&&
        <div>             
            <br />
            {taxData&&<div><div>            
                <Checkbox onChange={(e)=>setOnlyShowUnmatched(e.target.checked)}>只显示未匹配</Checkbox>
            </div>
            <Table loading={taxTouristsValidated===null} pagination={false} size="small" scroll={{y: 400}} 
                    dataSource={onlyShowUnmatched?taxTouristsValidated.filter(item=>!(item.match_result&&item.match_result.length===1)):taxTouristsValidated} >
                    <Column title="" render={(text, record, index)=>index+1} key="index" />                       
                    <Column title="姓" dataIndex="lastname" key="lastname" />   
                    <Column title="名" dataIndex="firstname" key="firstname" />   
                    <Column title="护照" dataIndex="passport" key="passport" />               
                    <Column title="匹配结果" width='50%' render={row=>{
                        const matchedTourists = row['match_result'];
                        if(!matchedTourists||matchedTourists.length===0){
                            return '没有匹配';
                        }else if(matchedTourists.length === 1){
                            return <span> {matchedTourists[0].passport_no} - {matchedTourists[0].lastname}, {matchedTourists[0].firstname} ({matchedTourists[0].group_name})</span>
                        }else if(matchedTourists.length > 1){
                            return <div>多个匹配
                                {matchedTourists.map(item=><span>
                                    {item.passport_no} - {item.lastname}, {item.firstname} ({item.group_name})
                                    </span>)
                                }</div>
                        }
                    }} key="match" />   
            </Table>
            <br />
            <div>
                <Space>
                <Button onClick={()=>setStep(0)}><ArrowLeftOutlined />上一步</Button>
                <Button type="primary" onClick={()=>{buildTransactionList(); setStep(2)}}><ArrowRightOutlined /> 下一步</Button>
                </Space>
            </div></div>}
        </div>}

        <br />
        {step===2&&
        <div>
        <Tabs
            defaultActiveKey="0"
            items={[{key:'0',label:<span><CheckCircleOutlined  style={{color: 'green'}} />有效的 ({receiptsValid.length})</span>, children: 
                    <Table
                    dataSource={receiptsValid} 
                    pagination={false}
                    scroll={{y:400}}
                >
                    <Column title="" dataIndex="index" key="index" />       
                    <Column title="产品" dataIndex="product_name" key="product_name" />
                    <Column title="产品号" dataIndex="product_no" key="product_no" />                       
                    <Column title="数量" dataIndex="quantity" key="quantity" />               
                    <Column title="金额" dataIndex="amount" key="amount" />   
                    <Column title="Invoice No." dataIndex="invoice_no" key="invoice_no" />                                           

                    <Column title="姓名" render={row=>(row['taxRecord']['lastname']+', '+row['taxRecord']['firstname'])} key="name" />  
                    <Column title="护照号(系统)" render={row=>row.tax_match_result.passport_no} key="passport_no" />        
                     
                </Table>
                },
                {key:'1',label:<span><WarningOutlined style={{color: 'orange'}} />没发现游客 ({receiptsNoTourist.length})</span>, children: 
                    <Table
                    dataSource={receiptsNoTourist} 
                    pagination={false}
                    scroll={{y:400}}
                >
                    <Column title="" dataIndex="index" key="index" />       
                    <Column title="产品" dataIndex="product_name" key="product_name" />   
                    <Column title="产品号" dataIndex="product_no" key="product_no" />
                    <Column title="数量" dataIndex="quantity" key="quantity" />               
                    <Column title="金额" dataIndex="amount" key="amount" />   
                    <Column title="Invoice No." dataIndex="invoice_no" key="invoice_no" />                                           

                    <Column title="姓名" render={row=>(row['taxRecord']['lastname']+', '+row['taxRecord']['firstname'])} key="name" />  
                    <Column title="护照号" dataIndex="passport_no" key="passport_no" />        
                     
                </Table>
                },
                {key:'2',label:<span><WarningOutlined style={{color: 'orange'}} />无退税记录 ({receiptsInvalid.length})</span>, children: 
                <Table
                dataSource={receiptsInvalid} 
                pagination={false}
                scroll={{y:400}}
                size='small'
            >
                <Column title="" dataIndex="index" key="index" />             
                <Column title="产品" dataIndex="product_name" key="product_name" />   
                <Column title="产品号" dataIndex="product_no" key="product_no" />
                <Column title="数量" dataIndex="quantity" key="quantity" />               
                <Column title="金额" dataIndex="amount" key="amount" />   
                <Column title="Invoice No." dataIndex="invoice_no" key="invoice_no" />
            </Table>
            }
            ]}
        />
        <br />
       <div>
            <Space>
                <Button onClick={()=>setStep(1)}><ArrowLeftOutlined />上一步</Button>
                <Button disabled={receiptsNoTourist.length>0} type="primary"><SendOutlined />提交</Button>
                <Button onClick={()=>submit()}>提交二</Button>
             </Space>
             <br /><br />
             <Alert type="info" message="注：系统只导入有效的记录" />                                        
        </div>
        </div>
        }
        </div>

        {step===3&&<Result
            title="导入成功"
            subTitle="导入成功"
            extra={[
            <Link to='/transaction/list'><Button type="primary" key="back">
                回到列表
            </Button></Link>
            ]}
  />}

    </div>
}