import { OrderedListOutlined, RightOutlined, ShopOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Result, Row, Space } from "antd"
import {FetchWithAuth} from 'digimaker-ui/util';
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export const DashBoard = ()=>{
  
   const iconStyle = {fontSize: 28};

    const colStyle = {style:{padding: 5}};

    const [transactionCount, setTransactionCount] = useState([]);
    const [transactionAmount, setTransactionAmount] = useState([]);
    const [groupCount, setGroupCount] = useState([]);

    useEffect(()=>{
        FetchWithAuth('/api/ns/transaction/statistics').then((data)=>{
            const list =[];
            for( let d=30;d>0;d--){
                const time =moment().subtract(d-1, 'days');
                list.push({date:time.format('YYYY-MM-DD'), 
                            date_day: time.format('D'),                 
                            count:0,
                            imports:[]})
            }

            for(let i=0;i<data.data.length;i++){
                const item = data.data[i];
                const importTime = moment(item.import_time);
                const date = importTime.format('YYYY-MM-DD');
                const index = list.findIndex(i=> i['date']===date);

                const count = item['count'];
                if(index !== -1){
                    let listitem = list[index];                    
                    list[index] = {...listitem, 
                                    count: listitem['count'] + count,
                                    imports: [...listitem['imports'],{time: importTime.format('HH:mm'), count: count}]
                                 };
                }
            }
            setTransactionCount(list);
         });

         FetchWithAuth('/api/ns/transaction/count_amount').then((data)=>{
            const list = data.data;
            for(const i in list){
                list[i]['amount'] = list[i]['amount']/100;
                list[i]['date_day'] = moment(list[i]['shopping_date']).format('MM-DD');
            }
            setTransactionAmount(list);
         });

         FetchWithAuth('/api/ns/tourist_group/count').then((data)=>{
            const list = data.data;    
            for(const i in list){
                list[i]['date_day'] = moment(list[i]['arrive_date']).format('DD');
            }        
            setGroupCount(list);
         });
    },[]);

    const data = [
        {
          name: '10',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: '9',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: '8',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: '7',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        }       
      ];

    return <div>

    <h2>面板</h2>
    <div>
        <h3>快速进入</h3>
        <div {...colStyle}>
        <div>
            <Link to="/transaction/import"><Button>导入购物记录</Button></Link>
        </div>
        <br />
        <div>
        <Space>
            <Link to="/transaction/list"><RightOutlined />购物记录</Link>
            <Link to="/group/list"><RightOutlined />旅行团</Link>            
            <Link to="/guide/list"><RightOutlined />导游列表</Link>            
        </Space>
        </div>
        </div>
    </div>

    <br />

    <h3>统计</h3>

    <Radio.Group defaultValue={"a"} buttonStyle="solid">
        <Radio.Button value="a">最近一月</Radio.Button>
        <Radio.Button value="b">最近一周</Radio.Button>
        <Radio.Button value="d">自定义</Radio.Button>        
    </Radio.Group>
    <br />
    <div>
    <Row>
        <Col  span={8} {...colStyle}>
        <Card title="购物记录导入">
            <div>        
            <BarChart width={350} height={300} data={transactionCount}>
            <Tooltip cursor={{stroke: '#cccccc', strokeWidth: 1, fill:'#fcfcfc'}} content={(item)=>{
                if (item.active && item.payload && item.payload.length) {
                        if(item.payload[0].value != '0'){
                            return <div style={{background:'white', border:'1px solid #cccccc', padding: 5, fontSize:'90%'}}>
                            {item.payload[0].payload.date} <br />
                            {item.payload[0].payload.imports.map((item)=><div>{item.time} {item.count}</div>)}
                        </div>
                        }else{
                            return <></>
                        }
                }
                else{
                    return <div></div>;
                }
                }
                } />
            <XAxis dataKey="date_day" label={{value:'导入日期', offset:-3, position:"insideBottom" }}>
            </XAxis>
            <YAxis />
            <Bar dataKey="count" fill="#0f963e">
                <LabelList dataKey="count" position="top" />
            </Bar>
            </BarChart>
            </div>
        </Card>
        </Col>

        <Col  span={8} {...colStyle}>
        <Card title="消费金额">
            <div>              
            <BarChart width={350} height={300} data={transactionAmount}>
            <Tooltip cursor={{stroke: '#cccccc', strokeWidth: 1, fill:'#fcfcfc'}} content={(item)=>{
                if (item.active && item.payload && item.payload.length) {
                            return <div style={{background:'white', border:'1px solid #cccccc', padding: 5, fontSize:'90%'}}>
                            {item.payload[0].payload.amount} <br />
                            {item.payload[0].payload.shopping_date}
                        </div>
                        
                }
                else{
                    return <div></div>;
                }
                }
                } />
            <XAxis dataKey="date_day" label={{value:'消费日期', offset:-3, position:"insideBottom" }}>
            </XAxis>
            <YAxis label={{ value: '', angle: -90, position: 'insideLeft' }} />
            <Bar dataKey="amount" maxBarSize={30} fill="#1c94ea">
                <LabelList dataKey="amount" position="top" />
            </Bar>
            </BarChart>
            </div>
        </Card>
        </Col>

        <Col span={8} {...colStyle}>
        <Card title="旅行团">
            <div>              
            <BarChart width={350} height={300} data={groupCount}>
            <Tooltip cursor={{stroke: '#cccccc', strokeWidth: 1, fill:'#fcfcfc'}} content={(item)=>{
                if (item.active && item.payload && item.payload.length) {
                            return <div style={{background:'white', border:'1px solid #cccccc', padding: 5, fontSize:'90%'}}>
                            {item.payload[0].payload.count}<br />{item.payload[0].payload.arrive_date}
                        </div>
                        
                }
                else{
                    return <div></div>;
                }
                }
                } />
            <XAxis dataKey="date_day" label={{value:'到达日期', offset:-3, position:"insideBottom" }}>
            </XAxis>
            <YAxis label={{ value: '', angle: -90, position: 'insideLeft' }} />
            <Bar dataKey="count" fill="#8db40e">
                <LabelList dataKey="count" position="top" />
            </Bar>
            </BarChart>
            </div>
        </Card>
        </Col>        
    </Row>
    </div>
    </div>
}