import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Form, Input, Result, Row, Space } from "antd"
import {FetchWithAuth} from 'digimaker-ui/util';
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

export const AddTourist = ()=>{
    const [success, setSuccess] = useState(false);

    const [group, setGroup] = useState({} as any);
    const [form] = Form.useForm();

    const [values, setValues] = useState([]);

    const params = useParams();
    
    const keys = ["firstname", "lastname", "passport"];

    useEffect(()=>{
        FetchWithAuth('/api/ns/tourist_group/get?id='+params.group).then((data)=>{
            setGroup(data.data);
         });
        addRow();
    },[]);

    const addRow = ()=>{
        let obj = {};
        keys.map((k)=>obj[k]='');
        console.log(obj);
        setValues( [...values, obj] )
    }


    const deleteRow = (i)=>{
        let v = [...values];
        console.log(i);
        v.splice(i, 1)
        setValues(v);
    }
   
    const inputFileRef = React.useRef(null);

    const uploadFile = (e)=>{
        let file = e.target.files[0];
       
        let fileReader = new FileReader();
        fileReader.onloadend = (e)=>{
           fileSelected(fileReader.result);
        }
        fileReader.readAsText(file);
    }

    
    const fileSelected = (text)=>{        
        let keys = [];

        const spliter = ';';

        const list = [];
        text.split("\n").map((line, i)=>{
            const arr = line.split(spliter);
            if( i===0 ){
                arr.map(item=>keys.push(item.trim()));
            }else{
                let obj = {};
                arr.map((item,i)=>{
                    obj[keys[i]] = item.trim()
                });
                list.push(obj);
            }
        })

        setValues(list);
        form.setFieldsValue(list);        
    }

    const submit = (data)=>{
        let arr = [];
        Object.keys(data).map((k)=>{
            arr.push(data[k]);
        })
        FetchWithAuth('/api/ns/tourist/add?group='+params['group'],{method:'POST', body: JSON.stringify(arr)}).then((resp)=>{
            if(resp.data === 1){
                setSuccess(true)
            }
         });
    }

    const colProps = {style:{padding: '2px 5px'}}

    return <div>
        <h2>{group.name} - 添加成员</h2>

        <Space>
        <Button onClick={()=>addRow()}>添加行</Button>
        <Button onClick={()=>inputFileRef.current.click()}>导入csv</Button>
        <input style={{display:'none'}} type="file"
        name="myFile"
        ref={inputFileRef}
        accept=".csv"
        onChange={uploadFile} />
        </Space>

        <br />        <br />

        {success&&<Result
            status="success"
            title="添加成功"
            subTitle="成员已经添加"
            extra={[
            <Link to='/group/list'><Button type="primary" key="back">
                回到列表
            </Button></Link>,
            <Button key="add" onClick={()=>setSuccess(false)}>再次添加</Button>,
            ]}
        />}

        {!success&&<Form form={form} onFinish={submit}>
            {values.map((row, i)=><Row key={JSON.stringify(row)+i}>
            <Col span={4} {...colProps}>
                <Form.Item
                label="姓"
                name={[i, 'lastname']}
                rules={[{ required: true, message: '请输入姓' }]}
                >
                <Input />
                </Form.Item>
            </Col>
            <Col  span={4} {...colProps}>
                <Form.Item
                label="名"
                name={[i,'firstname']}
                rules={[{ required: true, message: '请输入名' }]}
                >
                <Input />
                </Form.Item>
            </Col>
            <Col span={4} {...colProps}>
                <Form.Item
                label="护照号"
                name={[i, 'passport']}
                rules={[{ required: true, message: '请输入护照号' }]}
                >
                <Input />
                </Form.Item>
            </Col>
            <Col span={4} {...colProps}>
                <Form.Item
                label="电话"
                name={[i,'mobile']}
                >
                <Input />
                </Form.Item>
            </Col>
            <Col span={6} {...colProps}>
                <Form.Item
                label="邮件"
                name={[i,'email']}
                >
                <Input type="email" />
                </Form.Item>
            </Col>
            <Col>
                <Button type="text" title="Delete row" onClick={()=>deleteRow(i)}><DeleteOutlined /></Button>
            </Col>
            </Row>)}

            <div>
            <Space>
                <Button type="primary" htmlType="submit">确定</Button>
                <Button htmlType="reset">重设</Button>
                <Button type="link" onClick={()=>window.history.back()}>返回</Button>
            </Space>
       </div>
        </Form>}


       
    </div>
}