import { Button, Card, Col, Grid, Row, Space, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {FetchWithAuth} from 'digimaker-ui/util';
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { TransactionList } from "./TransactionList";

const { Column, ColumnGroup } = Table;

const ViewTouristGroup = (props:{id?:number})=>{

    const [list, setList] = useState([]);
    const [group, setGroup] = useState({} as any);

    const params = useParams();

    useEffect(()=>{
       fetchList();
    },      
      [props.id]);

    const fetchList = ()=>{
        FetchWithAuth('/api/ns/tourist_group/get?id='+(params.group||props.id)).then((data)=>{
            const listData = data.data;
            setGroup(data.data);
         });

      FetchWithAuth('/api/ns/tourist/list?group='+(params.group||props.id)).then((data)=>{
        const listData = data.data;
        setList(listData);
     });
    }
    
    return <div>
        <Card size="small" title={group.name}>
            <div>              
            <Row>
                <Col span={8}><label>到达时间: </label> <Moment format="YYYY-MM-DD">{group.arrive_date}</Moment></Col>
                <Col span={8}><label>离开时间: </label> <Moment format="YYYY-MM-DD">{group.leave_date}</Moment></Col>                
            </Row>
            <Row>
                <Col span={8}><label>导游: </label> <Link to={"/guide/view/"+group.guide_id}>{group.guide_name}</Link></Col>
                <Col span={8}><label>导游二: </label> {group.guide2_name}</Col>                
            </Row>
            <Row>
                <Col span={8}><label>旅行社: </label> {group.agency_name}</Col>
                <Col span={8}></Col>                
            </Row>
            </div>
        </Card>

      <br />
      <Tabs items={[{key: '0', label:'成员', children:
          <div><Space><Link to={"/group/add_member/"+(params.group||props.id)}> <Button>添加成员</Button> </Link>   </Space>
          <Table dataSource={list} pagination={false}>
                <Column dataIndex={'id'} title='ID' />
                <Column dataIndex={'lastname'} title='姓' />
                <Column dataIndex={'firstname'} title='名' />
                <Column dataIndex={'passport_no'} title='护照号' />
                <Column render={row=><div>
                    <Space>
                        <Button>编辑</Button>
                        <Button>删除</Button>
                    </Space>
                </div>} title='操作' />
                        
            </Table>
            </div>},
            {key: '1', label:'购物记录', children: <div><TransactionList id={parseInt(params.group?params.group:"0")} /></div>}
    ]} />
    </div>
}

export default ViewTouristGroup;
