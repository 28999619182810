import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import util from 'digimaker-ui/util';
import { useNavigate } from "react-router";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const UserProfile = ()=>{
    const [data, setData] = useState('' as any);

    const navigate = useNavigate();

    useEffect( ()=>{
        FetchWithAuth('/api/user/current/admin').then(res=>{
            setData(res.data);
        });        
    },[]);

    if(!data){
        return <></>
    }

    return <Link to="/user/profile"><UserOutlined /><span> {data.metadata.name}</span></Link>;
}