import { Button, Form, Input, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import util from 'digimaker-ui/util';
import { useNavigate } from "react-router";
import { LogoutOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export const Logout = ()=>{
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [messageApi, contextHolder] = message.useMessage();

    const navigate = useNavigate();

    const signout = async ()=>{
        FetchWithAuth('/api/auth/token/revoke?token='+util.getRefreshToken()).then(res=>{
            navigate('/login');
        });        
    }

    return <a href="#" onClick={(e)=>{e.preventDefault();confirm({content:'确定登出？', onOk:signout})}}><LogoutOutlined /> 登出</a>;
}