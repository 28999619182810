import React from 'react';

export default class ErrorBoundary extends React.Component<any, {error:any, errorInfo:any}> {
  constructor(props:any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    if( error.code === '0001' ){
      window.location.href='/login';
      return
    }
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    let error = this.state.error;
    //todo: support different error hierarchy: 1) app level error. eg. refresh token expired.  2) view level eg. no access to a content/action.
    // maybe need different error boundary components / error types.
    if (error) {
      return (
        <div className="error-main alert alert-warning">
          <h2>Feil</h2>
          <div>{error&&<div>
            {error.code==='0001'&&<div>
                Du er logget ut
              <div>
                <a href="/login">Logg inn</a>
              </div>
            </div>}
            </div>}</div>
        </div>
      );
    }
    return this.props.children;
  }
}
