import { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import { Button, Popconfirm, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import { Link } from "react-router-dom";

export const ListProduct = ()=>{
    const [list, setList] = useState(null);
    const [count, setCount] = useState(-1);
    const [currentGroup, setCurrentGroup] = useState(0);
    const [activeTab, setActiveTab] = useState('0');
    const [currentPage, setCurrentPage] = useState(1);

    const pageSize = 5;

    useEffect(()=>{
       fetchList();
    },      
      [currentPage]);

    const fetchList = ()=>{
      FetchWithAuth('/api/content/list/product?sortby=id desc&offset='+(currentPage-1)*pageSize+'&limit='+pageSize).then((data)=>{
        const listData = data.data.list;
        setList(listData);
        setCount(data.data.count);
     });
    }

    return <div>
        <h2>产品管理</h2>
        <div>
        <Link to={"/create/3/product"}> <Button>创建产品</Button> </Link> 
        </div>
        <Table loading={list===null}
            dataSource={list} 
            pagination={{pageSize: pageSize, current: currentPage, total: count}}
            onChange={(p)=>{
              setCurrentPage(p.current)
            }}
            >
            <Column title="ID" dataIndex="id" key="age" />
            <Column title="商品号" dataIndex="product_number" key="product_number" />         
            <Column title="名称" dataIndex="title" key="title" />   
            <Column title="分类" render={row=>row['category']?.text} key="category" />               
            <Column title="价格" dataIndex="price" key="price" />            
            <Column title="操作" render={row=><div>
                <Space>
                    <Link to={"/group/edit/"+row['id']}> <Button>编辑</Button> </Link>          
                    <Popconfirm
                    title={'确认'}
                    description={"确认要删除 "+row['name']+'?'}
                    onConfirm={()=>{}}
                    okText="确定"
                    cancelText="否"
                  ><Button>删除</Button></Popconfirm>
                </Space>
            </div>} />
        </Table>
    </div>
}