
import { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import { Button, Popconfirm, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

export const AgencyList = ()=>{

    const [list, setList] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const pageSize = 5;

    useEffect(()=>{
       fetchList();
    },      
      [currentPage]);

    const fetchList = ()=>{
      FetchWithAuth('/api/ns/agency/list?offset='+((currentPage-1)*pageSize)+'&limit='+pageSize).then((data)=>{
        setList(data.data.list);
        setCount(data.data.count)
     });
    }

    return <div>        
        <h2>旅行社</h2>
        <Link to="/agency/add"><Button>添加</Button></Link>
        <Table pagination={{pageSize:pageSize, total: count}} onChange={(pagination)=>{
          setCurrentPage(pagination.current)
        }} loading={list===null} dataSource={list}>
            <Column title="ID" dataIndex="id" key="age" />
            <Column title="名称" render={row=><Link to={"/agency/view/"+row.id}>{row['name']}</Link>} key="name" />                        
            <Column title="操作" render={row=><div>
                <Space>
                    <Link to={"/agency/edit/"+row.id}> <Button>编辑</Button> </Link>
                    <Popconfirm
                    title={'确认'}
                    description={"确认要删除 "+row['name']+'?'}
                    okText="确定"
                    cancelText="否"
                  ><Button>删除</Button></Popconfirm>
                </Space>
            </div>} />
        </Table>
    </div>
}