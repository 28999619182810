import { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import { Button, Table } from "antd";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Column, ColumnGroup } = Table;

export const TransactionList = (props:{id:number})=>{
    const [list, setList] = useState(null);
    const [total, setTotal] = useState(-1);

    useEffect(()=>{
        fetchList();
     },      
       []);
 
     const fetchList = ()=>{
       FetchWithAuth('/api/ns/tourist_group/transaction_list?id='+props.id).then((data)=>{
         setList( data.data.list );
         setTotal(data.data.commission)
      });
     }

    return <div>
        {total!==-1&&<div className="right">
            合计佣金: {(total/100).toLocaleString()}
        </div>}

        <Table loading={list===null} dataSource={list} pagination={false}>
            <Column title="ID" dataIndex="id" key="age" />
            <Column title="游客" dataIndex="tourist_name" key="tourist_name" />                      

            <Column title="产品名" render={(row)=><div>{row['product_id']>0?<Link to={"/product/view/"+row['product_id']}>{row['product_name']}</Link>:row['product_name']}</div>} key="product_name" />         
            <Column title="数量" dataIndex="quantity" key="quantity" />    
            <Column title="金额" render={row=>(row['amount']/100).toLocaleString()} key="amount" /> 

            <Column title="佣金类别" render={row=>row['product_in_system']['category']} key="category" />   
            <Column title="佣金" render={row=>(row['commision']/100).toLocaleString()} key="commision" />   
                        
               
            <Column title="Invoice No." dataIndex="invoice_no" key="invoice_no" />    
            
            <Column title="购物时间"  render={row=> <Moment format="YYYY-MM-DD HH:mm">{row['shopping_time']}</Moment>} key="transaction_time" />
            <Column title="导入时间"  render={row=><div>
                <Moment format="YYYY-MM-DD HH:mm">{row['import_time']}</Moment>
                 {row['import_note']&&<Button type="link" size="small"><InfoCircleOutlined title={row['import_note']} /></Button>}
            </div>} key="import_time" />                 
                         
        </Table>

    </div>
}