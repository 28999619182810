export const util = {
    csvToObj:async (text:string)=>{
        let keys = [];

        const spliter = ';';

        // const list = [];

        const csvtojson=require("csvtojson");
        const list = await csvtojson({delimiter:';'}).fromString(text);       
        return list;
    },
    washQuote:(text:string)=>{
        let result = text;
        if(text.startsWith("\"")){
            result = result.substring(1);
        }
        if(text.endsWith("\"")){
            result = result.substring(0,result.length-2);
        }
        return result;
    },
    stringToFloat: (str:string)=>{
        str = str.replaceAll(",", ".");
        return parseFloat(str)
    }

}