import { Button } from "antd";
import React, { ReactNode, useState } from "react";
import { util } from "./uti";

export const UploadCsv = (props:{button: string|ReactNode, validate?:(file)=>boolean, onFinish: (data, file)=>void})=>{
    const inputFileRef = React.useRef(null);
    const [file, setFile] = useState(null);

    const uploadFile = (e)=>{
        let file = e.target.files[0];
       
        if( props.validate && !props.validate(file) ){
            return;
        }
        let fileReader = new FileReader();
        fileReader.onloadend = (e)=>{
           fileSelected(fileReader.result, file);
        }
        fileReader.readAsText(file);
    }

    
    const fileSelected = async (text, file)=>{        
        const list = await util.csvToObj(text);
        setFile(file);
        props.onFinish(list, file);
          
    }

    return <div>
        <Button onClick={()=>inputFileRef.current.click()}>{props.button}</Button>
        <input style={{display:'none'}} type="file"
        name="myFile"
        ref={inputFileRef}
        accept=".csv"
        onChange={uploadFile} />

        {file&&<span>&nbsp;{file.name}</span>}
    </div>
}