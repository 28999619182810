import { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import { Button, Checkbox, Col, DatePicker, Input, Row, Select, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";

export const TransactionList = ()=>{
    const [list, setList] = useState(null);
    const [count, setCount] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 15;

    useEffect(()=>{
        fetchList();
     },      
       [currentPage]);
 
     const fetchList = ()=>{
       FetchWithAuth('/api/ns/transaction/list?sortby=id desc&offset='+(currentPage-1)*pageSize+'&limit='+pageSize).then((data)=>{
         const listData = data.data.list;
         setList(listData);
         setCount(data.data.count);
      });
     }

    return <div>

    <h2>购物记录</h2>
    <Row>
        <Col span={12}>
        <Space>
            <Link to="/transaction/import"><Button>导入记录</Button></Link>            
            <Button>删除</Button>            
        </Space>
        </Col>
        <Col>        
        <div>
                <Space>
                    <div>从 <DatePicker /> 到 <DatePicker /></div>
                <div>
                    旅行团: 
                    <Select placeholder="选择" showSearch style={{width: 200}}>
                    <Select.Option>[2023-10-10]北欧三日游</Select.Option>
                </Select>
                </div>
                </Space>
            </div>
        </Col>
    </Row>
    <Table size="small" loading={list===null}
            dataSource={list} 
            pagination={{pageSize: pageSize, position:['topRight', 'bottomRight'], showTotal:(total)=><span>总计{total}条记录</span>, size:'default', current: currentPage, total: count}}
            onChange={(p)=>{
              setCurrentPage(p.current)
            }}
            >
            <Column title="选择" render={(row)=><div><Checkbox key={row['id']} style={{width: '100%'}} /></div>} />
            <Column title="ID" dataIndex="id" key="id" />
            <Column title="商品" render={(row)=><div>{row['product_id']>0?<Link to={"/product/view/"+row['product_id']}>{row['product_name']}</Link>:row['product_name']}</div>} key="product_name" />         
            <Column title="产品分类" render={row=>(row.product_in_system?.category)} key="category" />   
            <Column title="商品号" dataIndex="product_number" key="product_number" />
            <Column title="数量" dataIndex="quantity" key="quantity" />
            <Column title="金额" render={row=>(row['amount']/100).toLocaleString()} key="amount" />   
            <Column title="购买人" render={(row)=><Link to={"/group/view/"+row['tourist_group_id']}>{row['tourist_name']}</Link>} key="tourist_name" />   
            <Column title="Invoice No." dataIndex="invoice_no" key="invoice_no" /> 
            <Column title="购物时间"  render={row=> <Moment format="YYYY-MM-DD HH:mm">{row['shopping_time']}</Moment>} key="transaction_time" />
            <Column title="导入时间"  render={row=><div>
                <Moment format="YYYY-MM-DD HH:mm">{row['import_time']}</Moment>
                 {row['import_note']&&<Button type="link" size="small"><InfoCircleOutlined title={row['import_note']} /></Button>}
            </div>} key="import_time" />
            <Column title="操作" fixed="right" render={row=><div>
                <Space>
                <Link to={"/"}><Button title="删除" size="small" type="link"><DeleteOutlined /></Button></Link>   
                    <Link to={"/var/"+row['import_file']}>查看文件</Link>                                            
                </Space>
            </div>} />
        </Table>
    </div>
}