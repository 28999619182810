import { useEffect, useState } from "react";
import {FetchWithAuth} from 'digimaker-ui/util';
import { Button, Card, Popconfirm, Space, Table, Tabs, TabsProps } from "antd";
import Column from "antd/es/table/Column";
import { Link, useParams } from "react-router-dom";
import {ViewContent} from 'digimaker-ui';
import Moment from "react-moment";

export const ViewGuide = ()=>{
    let { id } = useParams();
    
    const [data, setData] = useState(null);
    const [groupList, setGroupList] = useState(null);
    

    useEffect(()=>{
        FetchWithAuth('/api/content/get/user/'+id).then((res)=>{
            setData(res.data);
         });
         FetchWithAuth('/api/ns/guide/list_groups?id='+id).then((res)=>{
          setGroupList(res.data);
       });
         
    },      
    []);   

    if(data === null){
        return <div>...</div>
    }

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: `旅行团记录`,
          children: <div><Table
          loading={groupList==null}
          dataSource={groupList}            
            >
            <Column title="ID" dataIndex="id" key="name" />
            <Column title="名称"  render={row=><Link to={"/group/view/"+row['id']}>{row['name']}</Link>} key="name" />
            <Column title="到达时间" render={row=><Moment format="YYYY-MM-DD">{row['arrive_date']}</Moment>} key="arrive_time" />
            <Column title="离开时间" render={row=><Moment format="YYYY-MM-DD">{row['leave_date']}</Moment>} key="leave_time" />   
            <Column title="佣金" render={row=><div>{(row['comission']/100).toLocaleString()}</div>} key="commission" />               
           </Table></div>,
        },
        {
          key: '2',
          label: `结算`,
          children: <div>ggg</div>,
        },
      ];

    return <div>
        
        <h2>查看导游</h2>
        <Card size="small" title='导游信息'>
            <ViewContent content={data} />
        </Card>


       <br />

        <Tabs defaultActiveKey="1" items={items} onChange={()=>{}} />        
        
    </div>
}